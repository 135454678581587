.container {
}

.tourStops {
  position: fixed;
  top: calc(48px + env(safe-area-inset-top));
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  height: 132px;
  z-index: 20;
  background-color: var(--color-background);
  padding: 6px 2px;
}

.tourStopContainer {
  display: block;
  color: inherit;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 2px;

  & img {
    display: block;
    width: 68px;
    height: 68px;
    margin: 0 6px 2px 6px;
  }

  & h3 {
    text-align: center;
    margin: 0;
    padding: 0 0 4px 0;
    font-size: 13pt;
    border-bottom: 2px solid transparent;

    &.selected {
      color: var(--color-accent);
      border-bottom-color: var(--color-accent);
    }
  }
}

.stopContent {
  position: relative;
  margin-top: 132px;
}

.overviewContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-grey4);
  opacity: 0;
  transition: 0.5s opacity;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.overviewContainerActive {
  opacity: 1;
  pointer-events: auto;
}

.overviewMap {
  flex: 1;
}

.closeOverviewButton {
  background-color: var(--color-accent);
  width: 100%;
  padding: 12px 12px max(12px, env(safe-area-inset-bottom)) 12px;
  font-size: 17pt;
  cursor: pointer;
  display: block;
}
