.infoWindow {
  color: black;
  font-size: 12pt;

  & p {
    margin: 2px 0 2px 35px;
  }

  & img {
    position: absolute;
    left: 5px;
    top: 22px;
    width: 40px;
  }
}

.distance {
  font-weight: 600;
}
