.mapContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.map {
  flex: 1
}

.viewVideoButton {
  flex: 0;
  padding: 12px 12px max(12px, env(safe-area-inset-bottom)) 12px;
  width: 100%;
}

.videoContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.videoContent {
  flex: 1;
  max-width: 770px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.video {
  flex: 0;
  width: 100%;
  background-color: black;
}

.videoDetails {
  padding: 12px;
  overflow: auto;
}

.name {
  text-align: center;
  margin-bottom: 8px;
  color: var(--color-accent);
  font-size: 25pt;
  margin-top: 16px;
}

.description {
  text-align: center;
  font-size: 13pt;
}

.instagramButton {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--color-accent);
  margin: 0 auto 8px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewMapButton {
  display: block;
  margin: 0 auto;
  background-color: transparent;
  border: 0;
  outline: 0;
  color: inherit;
  cursor: pointer;
  text-decoration: underline;

  &:hover, &:active {
    text-decoration: none;
  }
}

.nextStopButton {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  padding: 12px 12px max(12px, env(safe-area-inset-bottom)) 12px;
}
