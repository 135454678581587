.container {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;
  max-width: 770px;
  display: flex;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
}

.iconButton {
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  margin: 0;

  & img {
    display: block;
    height: 32px;
  }
}

.countdown {
  font-size: 1.2em;
  font-family: 'futura-pt-condensed', sans-serif;
  font-weight: 800;
}
