.container {
  margin: 0 12px;
  width: 100vw;
  max-width: 600px;
}

.content {
  background-color: #FFFFFF;
  color: #000000;
  font-size: 1.2em;
  font-weight: 500;
  border-left: 8px solid #F7AB16;
  padding: 20px 16px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
}

.message {
  flex: 1;
}

.button {
  margin-left: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  font-size: 1.1em;

  &:hover, &:active {
    opacity: 0.5;
  }
}
