.link {
  height: 48px;
  background-color: #F7AB16;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 17pt;
  font-family: 'futura-pt-condensed', sans-serif;
  font-weight: 800;
}

.disabled {
  opacity: 0.25;
}
