.container {
  max-width: 770px;
  margin: 0 auto;
}

.content {
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.icon {
  width: 120px;
  height: 120px;
  align-self: center;
}

.name {
  text-align: center;
  margin: 16px 0 0 0;
}

.description {
  margin: 16px 0 0 0;
}

.buttons {
  margin-top: 32px;
}

.button {
  margin-bottom: 16px;
}
