@import-normalize;

:root {
  --color-accent: rgb(250, 182, 28);
  --color-secondary: #469DC9;
  --color-background: rgb(76, 81, 81);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'futura-pt', sans-serif;
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'futura-pt-condensed', sans-serif;
  font-weight: 500;

  & span {
    font-family: inherit;
    display: block;
  }

  & strong {
    font-family: 'futura-pt-condensed', sans-serif;
    font-weight: 800;
  }
}

button {
  font-family: 'futura-pt-condensed', sans-serif;
  font-weight: 800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
}

body {
  background-color: var(--color-background);
  height: 100%;
}

#root {
  min-height: 100%;
}

.firebase-emulator-warning {
  display: none;
}

a {
  -webkit-tap-highlight-color: transparent;
}

input {
  border: 0;

  &:focus {
    outline: none;
  }
}

button {
  border: 0;
}

p {
  line-height: 1.4;
}
