.container {
  max-width: 770px;
  margin: 0 auto;
}

.welcomeHeading {
  font-size: 30pt;
  text-align: center;
}

.list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;

  & li {
    padding: 16px;

    & a {
      display: block;
      color: inherit;
      text-decoration: none;

      &:hover, &:active {
        opacity: 0.5;
      }
    }

    & h3 {
      text-align: center;
      font-size: 17pt;
      padding: 0;
      margin: 16px 0 0 0;
    }
  }
}
