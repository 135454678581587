.container {
  min-height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-accent);
  z-index: 10;
  padding-top: env(safe-area-inset-top);
}

.content {
  position: relative;
  padding-top: calc(48px + env(safe-area-inset-top));
  color: #ffffff;
  min-height: 100vh;
}
