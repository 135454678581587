.input {
  height: 48px;
  background-color: #FFFFFF;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  border: 2px solid transparent;
  font-size: 17pt;
  font-family: "futura-pt-condensed", sans-serif;
  font-weight: 500;

  &:focus {
    border-color: #F7AB16;
  }
}
