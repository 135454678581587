.container {
  max-width: 770px;
  margin: 0 auto;
}

.headerImage {
  width: 100%;
}

.websiteLink {
  text-align: center;
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 16px;
  font-family: 'futura-pt-condensed', sans-serif;
  font-weight: 500;
  font-size: 27pt;
  border-bottom: 1px solid white;

  &:hover, &:active {
    text-decoration: underline;
  }
}

.list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 16px auto 40px auto;
  padding: 12px 12px 80px 12px;
  max-width: 600px;

  & li {
    border-bottom: 1px solid white;

    & a {
      display: flex;
      flex-direction: row;
      color: inherit;
      text-decoration: none;
      padding: 16px 0;
      font-size: 1.2em;
      font-weight: 500;

      & span {
        flex: 1;
      }
    }
  }
}

.signOutButton {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--color-accent);
  width: 100%;
  padding: 12px 12px max(12px, env(safe-area-inset-bottom)) 12px;
  font-size: 17pt;
  cursor: pointer;
  display: block;
}
