.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #363636;
  color: #ffffff;

  & h2, & h3 {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  & h2 {
    font-size: 2em;
    margin-bottom: 8px;
  }

  & h3 {
    font-size: 1.3em;
    margin-bottom: 32px;
  }

  & img {
    width: 120px;
  }
}
