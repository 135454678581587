.container {
  max-width: 770px;
  min-height: 100%;
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 80px 32px;
}

.heading {
  text-align: center;
  font-size: 25pt;

  & span {
    margin-bottom: 2px;
  }
}

.description {
  text-align: center;
  font-size: 15pt;
}

.startButton {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--color-accent);
  width: 100%;
  padding: 12px 12px max(12px, env(safe-area-inset-bottom)) 12px;
  font-size: 17pt;
  cursor: pointer;
  display: block;
}
