.back {
  position: absolute;
  top: 12px;
  left: 12px;
}

.container {
  max-width: 770px;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
}

.content {
  position: relative;
  flex: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 770px) {
    justify-content: center;
  }

  @media screen and (min-width: 770px) {
    padding-top: 32px;
  }
}

.heading {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 25pt;
}

.heading1, .heading2 {
  margin-bottom: 8px;
}

.form {
  display: flex;
  flex-direction: column;
}

.codeInput {
  margin: 32px 0 24px 0;
}

.terms {
  margin: 0 0 24px 0;

  & input {
    float: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    padding: 2px;
    margin-right: 12px;
    background-clip: content-box;
    border: 1.5px solid #bbbbbb;
    border-radius: 3px;
    background-color: #e7e6e7;

    &:checked{
      background-color: #F7AB16;
    }

    &:focus{
      outline: none !important;
    }
  }

  & label {
    font-size: 1.2rem;

    & a {
      color: white;
    }
  }
}
