.controlButton {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  color: rgba(102, 102, 102);
  border-radius: 2px;
  cursor: pointer;
}

.locationShown {
    color: var(--color-secondary)
  }
